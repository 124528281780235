.product_details {
  position: relative;
}
.product_details  img{
  width: 100%;
}
.product_details .paragraph{
  height: 400px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.product_details .paragraph p{
font-size:20px;
}
.product_details .paragraph p svg{
  font-size: 24px;
  margin-left: 10px;
}