:root{
  --overlay-color : #171635b5;
}
*{
  box-sizing: border-box !important;
  font-family: 'Cairo', sans-serif;
}
body{
  /* height: 1000vh; */
}

.heading {
  text-align: center;
  }
.heading h1{
  font-size: 60px;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  }
  ::-webkit-scrollbar {
    width: 15px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #d0d0d0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #0d6efd;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #94bdfb;
  }